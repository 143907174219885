<template>
  <ButtonAsLink
    v-if="asLink"
    ref="button"
    v-bind="$props"
    @click="$emit('click', $event)"
    @keydown="$emit('keydown', $event)"
  >
    <slot />
  </ButtonAsLink>
  <BaseButton
    v-else
    ref="button"
    v-bind="$props"
    @click="$emit('click', $event)"
    @keydown="$emit('keydown', $event)"
  >
    <slot />
  </BaseButton>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { buttonProps } from './ButtonProperties';
import BaseButton from './BaseButton.vue';
import ButtonAsLink from './ButtonAsLink.vue';

defineProps(buttonProps);
defineEmits<
{(event: 'click', payload: MouseEvent): void
  (event: 'keydown', payload: KeyboardEvent): void
}>();

const button = ref();
defineExpose({
  button,
});
</script>
