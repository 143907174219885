import { number, string, any } from 'vue-types';

import {
  borderInvert,
  borderInvertText,
  borderInvertHover,
  background,
  backgroundHover,
  focus,
  focusVisibleDefault,
  ColorType,
  text,
  textHover,
  ColorRange,
  softBackground,
  softBackgroundHover,
  softText,
  borderTransparentText,
} from '../styling/colorProperties';
import { btnPaddings, SizeType } from '../styling/sizeProperties';

export const buttonProps = {
  type: string<ColorType>().def('primary'),
  color: string<ColorRange>().def('normal'),
  size: string<SizeType>().def('normal'),
  icon: string(),
  class: any(),
  /** remove class */
  defaultClass: string(),
  text: string(),
  htmlType: string<'button' | 'submit' | 'reset'>().def('button'),
  disabled: Boolean,
  loading: Boolean,
  textLoading: string(),
  iconLoading: string().def('fas fa-spinner fa-spin'),
  tabindex: number(),
  dataTestId: string(),
  asLink: Boolean,
};

export const getTypeClass = (type: ColorType, colorRange?: ColorRange, disabled?: boolean) => {
  if (disabled) {
    return 'btn-disabled';
  }
  if (colorRange === 'invert') {
    return `${borderInvert[type]} ${borderInvertText[type]} ${borderInvertHover[type]}`;
  }
  if (colorRange === 'transparent') {
    return `${borderInvert[type]} ${borderTransparentText[type]} ${borderInvertHover[type]}`;
  }

  if (colorRange === 'soft') {
    return `
    ${softText[type]}
    ${softBackground[type]}
    ${softBackgroundHover[type]}
    ${borderInvert[type]}
    ${borderInvertHover[type]}`;
  }
  return `${background[type]} ${backgroundHover[type]}`;
};

export const getClasses = (
  type: ColorType,
  size: SizeType,
  // eslint-disable-next-line default-param-last
  defaultClass = 'inline-block rounded-lg font-semibold',
  colorRange?: ColorRange,
  disabled?: boolean,
) => {
  const sizeClass = btnPaddings[size];
  const typeClass = getTypeClass(type, colorRange, disabled);
  return `${sizeClass} ${typeClass} ${defaultClass} ${focusVisibleDefault} ${focus[type]}`;
};

export const getLinkClasses = (type: ColorType, disable?: boolean, loading?: boolean) => {
  const colors = text[type];
  if (disable || loading) {
    const classes = `${colors} cursor-not-allowed`;
    return disable ? `${classes} line-through` : classes;
  }
  return `${colors} ${textHover[type]} hover:underline`;
};
